<template>
  <b-container fluid>
    <b-row>
        <b-col v-if="saving">
            <b-spinner /> Saving...
        </b-col>

        <b-col v-if="user == null">
            <b-spinner /> Loading...
        </b-col>
    </b-row>

    <b-row v-if="(user != null) && !saving"  >
      <b-col>
        <b-card :header="'User edit'" class="mt-4">
          <b-form @submit.prevent="onSubmit">
            <form-field v-for="(field, name) in form.fields" :field="field" :key="name" :form="form" :name="name" /> 

            <b-button type="submit" variant="primary">Save</b-button>
            <b-button :to="{ name: 'portal-users' }" variant="secondary" class="ml-3">Back to users</b-button>
            <b-button v-if="false" :disabled="id == 'new'" @click="sendWelcomeMail()" variant="secondary" class="ml-3">Send welcome e-mail</b-button>
          </b-form>
        </b-card>

        <b-card :header="'User actions'" class="mt-4">
          <b-button @click="sendPasswordResetEmail" class="mr-3">Send password reset e-mail</b-button>
          <b-button @click="sendOnboardingEmail">Send onboarding e-mail</b-button>
        </b-card>

        <b-card v-if="form.values.PortalAdmin == 0" header="Rights" class="mt-4 mb-5">
            <b-table-simple small striped>
                <b-thead head-variant="dark">
                    <b-tr>
                        <b-th>Type</b-th>
                        <b-th>Name</b-th>
                        <b-th>Tags</b-th>
                        <b-th>
                          <b-button size="sm" variant="primary" :disabled="editingRightsIndex !== null" @click="addRow()">Add row</b-button>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody v-if="rightsLoading">
                  <b-tr>
                    <b-td colspan="4">
                      <b-spinner /> Loading...
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tbody v-if="!rightsLoading">
                    <b-tr v-for="(item, index) in rights" :key="index">
                      <template v-if="editingRightsIndex != index">
                        <b-th>{{ item.objectType.value }}</b-th>
                        <b-th>{{ item.object.value }}</b-th>
                        <b-th>
                          <span v-for="(serviceTag, index) in item.serviceTags" :key="index">
                            {{ serviceTag.value }}<template v-if="index < item.serviceTags.length-1">,</template>
                          </span>
                        </b-th>
                        <b-td>
                          <template v-if="['SubProviderID', 'BeneficiaryCode'].includes(item.objectType.key)">
                            <b-button size="sm" variant="primary" :disabled="editingRightsIndex !== null" @click="editRow(index)">Edit</b-button>
                            <b-button size="sm" variant="secondary" :disabled="editingRightsIndex !== null" @click="deleteRow(index)" class="ml-2">Delete</b-button>
                          </template>
                        </b-td>
                      </template>

                      <template v-if="editingRightsIndex == index">
                        <b-th>
                          <multiselect v-model="editingData.objectType" label="value" :options="rightsTypeOptions" />
                        </b-th>
                        <b-th>
                          <ApiLookup
                            v-if="editingData.objectType.key  === 'SubProviderID'" 
                            v-model="editingData.object" 
                            :searchOnMount="true"
                            :multiple="false" source="sub-providers" label="Description" keyname="SubProviderID" />

                          <ApiLookup
                            v-if="editingData.objectType.key  === 'BeneficiaryCode'" 
                            v-model="editingData.object" 
                            :searchOnMount="true"
                            keyname="BeneficiaryCode"
                            label="BeneficiaryCode"
                            :usePost="false"
                            :multiple="false" source="beneficiaries"  />

                          <p v-if="!editingData.objectType">Please select type</p>
                        </b-th>
                        <b-th>
                          <multiselect 
                            v-model="editingData.serviceTags" 
                            :multiple="true" track-by="key" label="value" 

                            :options="tagOptions" />
                        </b-th>
                        <b-td>
                          <b-button size="sm" variant="primary" :disabled="!editRightsValid" @click="saveRow()">Save</b-button>
                          <b-button size="sm" variant="primary" @click="cancelSaveRow()" class="ml-2">Cancel</b-button>
                        </b-td>
                      </template>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

          <b-button variant="primary" :disabled="editingRightsIndex !== null" @click="saveRights()" class="ml-3">Confirm changes</b-button>
          <b-button variant="secondary" :disabled="editingRightsIndex !== null" @click="loadRights()" class="ml-3">Undo changes</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import FormField from '@/components/FormField'

  export default {
    name: "PortalUserDetail",
    components: {
      FormField,
      ApiLookup: () => import('@/components/ApiLookup.vue'),
      'multiselect': () => import('vue-multiselect'),
    },
    data() {
      return {
        title: '',
        user: null,
        rights: [],
        rightsLoading: true,
        addType: '',
        saving: false,
        addingRow: false,
        editingRightsIndex: null,
        editingData: {},
        selectedRef: [],
        rightsTypeOptions: [
          { key: 'SubProviderID', value: 'Sub Provider' },
          { key: 'BeneficiaryCode', value: 'Beneficiary' },
        ],
        tagOptions: [
          { key: 'VW1', value: 'View' },
          { key: 'RB1', value: 'Release Builder' },
          { key: 'TR1', value: 'Trends' },
          { key: 'ROY', value: 'Analytics' },
          { key: 'ST1', value: 'Statements' },
        ],
        form: {
          values: null,
          validated: false,
          fields: {
            "Username": {
              label: "Username",
              required: true,
              type: "text"
            },
            "FullName": {
              label: "Full Name",
              required: true,
              type: "text"
            },
            "EMail": {
              label: "E-mail",
              required: true,
              type: "email"
            }, /*
            "Password": {
              label: "Password",
              required: false,
              type: "text"
            }, */
            "PortalAdmin": {
              label: "Admin",
              type: "checkbox",
            }
          }
        }  
      }
    },
    props: {
      id: String,
    },
    methods: {
      addRow() {
        let newdata = {
          objectType: '',
          object: null,
          serviceTags: [],
        }

        this.rights.push(newdata)
        this.editingRightsIndex = this.rights.length-1
        this.editingData = {...newdata}
        this.addingRow = true
        
      },
      saveRow() {
        this.rights[this.editingRightsIndex] = {...this.editingData} 
        this.editingRightsIndex = null
        this.addingRow = false
      },
      cancelSaveRow() {
        if (this.addingRow == true) {
          this.rights.splice(this.editingRightsIndex, 1)
        }
        this.editingRightsIndex = null
      },
      editRow(index) {
        this.editingRightsIndex = index
        this.editingData = {...this.rights[this.editingRightsIndex]}
      },
      deleteRow(index) {
          this.rights.splice(index, 1)
      },
      loadRights() {
        this.rightsLoading = true
        this.$http.get('portal-users/'+this.id+'/rights')
          .then(response => {
            this.rights = response.data
            this.rightsLoading = false
          })
          .catch((error) => {
            this.rightsLoading = false
            console.log(error)
            this.$notify({
                type: 'error',
                text: 'Error while loading'
              })
          });
      },
      saveRights () {
        this.rightsLoading = true

        this.$http.put('portal-users/'+this.user.PortalUserID+'/rights', this.rights)
          .then(() => {
            this.rightsLoading = false
            this.$notify({
              type: 'success',
              text: 'Rights saved'
            })
          })
          .catch((error) => {
            console.log(error)
            this.rightsLoading = false

            this.$notify({
              type: 'error',
              text: 'Something went wrong, please refresh and try again'
            })
          });

      },
      onSubmit () {
        this.saving = true

        if (this.id != 'new') {
          this.$http.put('portal-users/'+this.user.PortalUserID, this.form.values)
            .then(() => {
              this.load()
              this.saving = false
              this.$notify({
                type: 'success',
                text: 'User saved'
              })
            })
            .catch((error) => {
              console.log(error)

              this.$notify({
                type: 'error',
                text: 'Something went wrong, please refresh and try again'
              })
            });

        } else {
          this.$http.post('portal-users', this.form.values)
            .then((response) => {
              this.$router.push({ name: 'portal-user-detail', params: { id: response.data.PortalUserID } })
              this.id = response.data.PortalUserID
              this.load()
              this.saving = false
              this.$notify({
                type: 'success',
                text: 'User saved'
              })
            })
            .catch((error) => {
              console.log(error)

              this.$notify({
                type: 'error',
                text: 'Something went wrong, please refresh and try again'
              })
            });
        }
      },
      sendOnboardingEmail () {
        console.log('test')
        this.$http.put('portal-users/'+this.user.PortalUserID, {
          '_actions': ['sendOnboardingEmail']
        }).then(() => {
            
            this.$notify({
                type: 'success',
                text: 'Onboarding e-mail sent'
              })
          })
          .catch((error) => {
            console.log(error)
            this.$notify({
                type: 'error',
                text: 'Error while sending e-mail'
              })
          });
      },
      sendPasswordResetEmail () {
        console.log('test')
        this.$http.put('portal-users/'+this.user.PortalUserID, {
          '_actions': ['sendPasswordResetEmail']
        }).then(() => {
            
            this.$notify({
                type: 'success',
                text: 'Password reset e-mail sent'
              })
          })
          .catch((error) => {
            console.log(error)
            this.$notify({
                type: 'error',
                text: 'Error while sending e-mail'
              })
          });
      },
      load () {
        this.user = null

        if (this.id == 'new') {
          this.user = {
            Username: ''
          }
          this.$set(this.form, 'values', {...this.user})
          return
        }

        this.$http.get('portal-users/'+this.id)
          .then(response => {
            this.user = response.data
            this.$set(this.form, 'values', {...this.user})
            this.loadRights()
          })
          .catch((error) => {
            console.log(error)
            this.$notify({
                type: 'error',
                text: 'Error while loading'
              })
          });
      }
    },
    computed: {
      editRightsValid () {
        return this.editingData.objectType != '' && this.editingData.object && this.editingData.serviceTags.length > 0
      },
    },
    watch: {

    },
    beforeRouteUpdate(to, from, next) {
      console.log(to, from)
      next()
    },
    mounted() {
      this.load()
    }
  }
</script>

<style lang="scss" scoped>

</style>