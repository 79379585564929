var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _vm.saving
            ? _c("b-col", [_c("b-spinner"), _vm._v(" Saving... ")], 1)
            : _vm._e(),
          _vm.user == null
            ? _c("b-col", [_c("b-spinner"), _vm._v(" Loading... ")], 1)
            : _vm._e()
        ],
        1
      ),
      _vm.user != null && !_vm.saving
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-4", attrs: { header: "User edit" } },
                    [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _vm._l(_vm.form.fields, function(field, name) {
                            return _c("form-field", {
                              key: name,
                              attrs: {
                                field: field,
                                form: _vm.form,
                                name: name
                              }
                            })
                          }),
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Save")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                to: { name: "portal-users" },
                                variant: "secondary"
                              }
                            },
                            [_vm._v("Back to users")]
                          ),
                          false
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    disabled: _vm.id == "new",
                                    variant: "secondary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sendWelcomeMail()
                                    }
                                  }
                                },
                                [_vm._v("Send welcome e-mail")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-4", attrs: { header: "User actions" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-3",
                          on: { click: _vm.sendPasswordResetEmail }
                        },
                        [_vm._v("Send password reset e-mail")]
                      ),
                      _c(
                        "b-button",
                        { on: { click: _vm.sendOnboardingEmail } },
                        [_vm._v("Send onboarding e-mail")]
                      )
                    ],
                    1
                  ),
                  _vm.form.values.PortalAdmin == 0
                    ? _c(
                        "b-card",
                        {
                          staticClass: "mt-4 mb-5",
                          attrs: { header: "Rights" }
                        },
                        [
                          _c(
                            "b-table-simple",
                            { attrs: { small: "", striped: "" } },
                            [
                              _c(
                                "b-thead",
                                { attrs: { "head-variant": "dark" } },
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th", [_vm._v("Type")]),
                                      _c("b-th", [_vm._v("Name")]),
                                      _c("b-th", [_vm._v("Tags")]),
                                      _c(
                                        "b-th",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                size: "sm",
                                                variant: "primary",
                                                disabled:
                                                  _vm.editingRightsIndex !==
                                                  null
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addRow()
                                                }
                                              }
                                            },
                                            [_vm._v("Add row")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.rightsLoading
                                ? _c(
                                    "b-tbody",
                                    [
                                      _c(
                                        "b-tr",
                                        [
                                          _c(
                                            "b-td",
                                            { attrs: { colspan: "4" } },
                                            [
                                              _c("b-spinner"),
                                              _vm._v(" Loading... ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.rightsLoading
                                ? _c(
                                    "b-tbody",
                                    _vm._l(_vm.rights, function(item, index) {
                                      return _c(
                                        "b-tr",
                                        { key: index },
                                        [
                                          _vm.editingRightsIndex != index
                                            ? [
                                                _c("b-th", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.objectType.value
                                                    )
                                                  )
                                                ]),
                                                _c("b-th", [
                                                  _vm._v(
                                                    _vm._s(item.object.value)
                                                  )
                                                ]),
                                                _c(
                                                  "b-th",
                                                  _vm._l(
                                                    item.serviceTags,
                                                    function(
                                                      serviceTag,
                                                      index
                                                    ) {
                                                      return _c(
                                                        "span",
                                                        { key: index },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                serviceTag.value
                                                              )
                                                          ),
                                                          index <
                                                          item.serviceTags
                                                            .length -
                                                            1
                                                            ? [_vm._v(",")]
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "b-td",
                                                  [
                                                    [
                                                      "SubProviderID",
                                                      "BeneficiaryCode"
                                                    ].includes(
                                                      item.objectType.key
                                                    )
                                                      ? [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                size: "sm",
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.editingRightsIndex !==
                                                                  null
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.editRow(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Edit")]
                                                          ),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                              attrs: {
                                                                size: "sm",
                                                                variant:
                                                                  "secondary",
                                                                disabled:
                                                                  _vm.editingRightsIndex !==
                                                                  null
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteRow(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("Delete")]
                                                          )
                                                        ]
                                                      : _vm._e()
                                                  ],
                                                  2
                                                )
                                              ]
                                            : _vm._e(),
                                          _vm.editingRightsIndex == index
                                            ? [
                                                _c(
                                                  "b-th",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        label: "value",
                                                        options:
                                                          _vm.rightsTypeOptions
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editingData
                                                            .objectType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editingData,
                                                            "objectType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editingData.objectType"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-th",
                                                  [
                                                    _vm.editingData.objectType
                                                      .key === "SubProviderID"
                                                      ? _c("ApiLookup", {
                                                          attrs: {
                                                            searchOnMount: true,
                                                            multiple: false,
                                                            source:
                                                              "sub-providers",
                                                            label:
                                                              "Description",
                                                            keyname:
                                                              "SubProviderID"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editingData
                                                                .object,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editingData,
                                                                "object",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editingData.object"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm.editingData.objectType
                                                      .key === "BeneficiaryCode"
                                                      ? _c("ApiLookup", {
                                                          attrs: {
                                                            searchOnMount: true,
                                                            keyname:
                                                              "BeneficiaryCode",
                                                            label:
                                                              "BeneficiaryCode",
                                                            usePost: false,
                                                            multiple: false,
                                                            source:
                                                              "beneficiaries"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editingData
                                                                .object,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editingData,
                                                                "object",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editingData.object"
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    !_vm.editingData.objectType
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Please select type"
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-th",
                                                  [
                                                    _c("multiselect", {
                                                      attrs: {
                                                        multiple: true,
                                                        "track-by": "key",
                                                        label: "value",
                                                        options: _vm.tagOptions
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editingData
                                                            .serviceTags,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editingData,
                                                            "serviceTags",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editingData.serviceTags"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-td",
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          size: "sm",
                                                          variant: "primary",
                                                          disabled: !_vm.editRightsValid
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.saveRow()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Save")]
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          size: "sm",
                                                          variant: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.cancelSaveRow()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Cancel")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                variant: "primary",
                                disabled: _vm.editingRightsIndex !== null
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveRights()
                                }
                              }
                            },
                            [_vm._v("Confirm changes")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                variant: "secondary",
                                disabled: _vm.editingRightsIndex !== null
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loadRights()
                                }
                              }
                            },
                            [_vm._v("Undo changes")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }